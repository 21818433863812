<script>
import ApiService from "@services/api.service";

export default {
  name: "LearningGroup",
  data() {
    return {
      learningGroupList: [],
      filter: null,
      learningGroup: {
        description: null,
        coverImageUrl: null,
        filePayload: {
          filename: null,
          base64File: null
        }
      },
      loader: {
        button: false
      },
      newFile: null,
      showModal: false
    };
  },
  computed: {
    rowCount: function () {
      return this.learningGroupList.length;
    },
    editMode: function () {
      return this.learningGroup == null ? true : this.learningGroup.code != null;
    }
  },
  created() {
    this.loadLearningGroup();
  },
  methods: {
    loadLearningGroup() {
      ApiService.get("learning/category").then(resp => {
        this.learningGroupList = resp;
      });
    },
    openDetail(selectedRow) {
      if (selectedRow) {
        this.learningGroup = { ...selectedRow };
        this.learningGroup.filePayload = {
          filename: null,
          base64File: null
        };
      }
      this.showModal = true;
    },
    save() {
      if (!this.editMode) {
        this.loader.button = true;
        let payload = {
          modelObject: {
            description: this.learningGroup.description
          }
        };
        if (this.learningGroup.filePayload.base64File != null) payload.filePayload = this.learningGroup.filePayload;
        this.loadLearningGroup();
        ApiService.post("learning/category", payload)
          .then(() => {
            this.showToast("success", "Create Success");
            this.loadLearningGroup();
            this.showModal = false;
          })
          .finally(() => {
            this.loader.button = false;
          });
      } else {
        this.loader.button = true;
        let payload = {
          modelObject: {
            code: this.learningGroup.code,
            description: this.learningGroup.description
          }
        };
        if (this.learningGroup.filePayload.base64File != null) payload.filePayload = this.learningGroup.filePayload;
        ApiService.put("learning/category", payload)
          .then(() => {
            this.showToast("success", "Edit Success");
            this.loadLearningGroup();
            this.showModal = false;
          })
          .finally(() => {
            this.loader.button = false;
          });
      }
    },
    async deleteLearningGroup(item) {
      if (await this.showConfirm(`Delete Learning Group ${item.description} ?`)) {
        this.changeLoader(true);
        let qs = {
          code: item.code
        };
        ApiService.delete("learning/category", qs)
          .then(() => {
            this.showToast("success", "Delete Success");
            this.loadLearningGroup();
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    fileChanged(event) {
      var reader = new FileReader();
      this.learningGroup.filePayload.filename = event.target.files[0].name;
      reader.onloadend = () => {
        this.learningGroup.filePayload.base64File = reader.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    clearForm() {
      this.learningGroup = {
        description: null,
        coverImageUrl: null,
        filePayload: {
          filename: null,
          base64File: null
        }
      };
    }
  }
};
</script>

<template>
  <div>
    <b-btn class="mb-2" variant="outline-primary" @click.stop="openDetail(null)">Create</b-btn>
    <b-row>
      <b-col v-for="item in learningGroupList" :key="item.code" class="text-left mb-2" md="4">
        <b-card no-body class="h-100">
          <b-card-body>
            <b-card-title>{{ item.description }}</b-card-title>
          </b-card-body>
          <b-img fluid :src="item.coverImageUrl" />
          <b-card-body class="text-right">
            <b-button class="mr-2" variant="primary" @click="openDetail(item)"> View </b-button>
            <b-button variant="danger" @click="deleteLearningGroup(item)"> <FeatherIcon icon="TrashIcon" /> </b-button>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-modal v-model="showModal" centered size="lg" hide-footer :backdrop="true" @hidden="clearForm">
      <vue-element-loading :active="loader.button" color="#7367F0" spinner="bar-fade" />
      <div class="px-3 py-2">
        <b-row>
          <b-col class="text-center" align-self="center">
            <h2>{{ !editMode ? `Create New Learning Group` : `Edit Learning Group` }}</h2>
          </b-col>
        </b-row>
        <hr />
        <b-form @submit.stop.prevent="save">
          <b-row class="mb-2">
            <b-col md="4" align-self="center">
              <label>Learning Group</label>
            </b-col>
            <b-col md="8" align-self="center">
              <b-input v-model="learningGroup.description" class="form-control" />
              <small>Learning Group</small>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col md="4" align-self="center">
              <label>Cover Image</label>
            </b-col>
            <b-col md="8" align-self="center">
              <b-form-file
                class="ml-auto"
                placeholder="Upload more Image..."
                drop-placeholder="Drop image here..."
                accept="image/jpeg, image/png, image/jpg"
                @change="fileChanged"
              ></b-form-file>
            </b-col>
          </b-row>
          <b-row class="mt-5">
            <b-col>
              <b-btn block variant="danger" @click.stop="showModal = false" :disabled="loader.button">Cancel</b-btn>
            </b-col>
            <b-col>
              <b-btn block type="submit" variant="success" :disabled="loader.button">Save</b-btn>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-modal>
  </div>
</template>
